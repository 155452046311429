import request from '@/utils/request';
import Vue from 'vue';

export function getNewNotice(data) {
  return request({
    url: '/notice/getNewNotice?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
