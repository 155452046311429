<template>
  <div class="homeView">
    <van-sticky>
      <van-nav-bar :right-text="$t('选择语言')" @click-right="onClickRight"> 
        <template #title>
          <img class="logo_1" src="@/assets/images/logo-zh-CN.png" alt />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="banner banner_auto">
      <div class="banner_bg"></div>
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(val, index) in adlist" :key="index" @click="linkUrl(val.url)">
          <img :src="$imageUrl + val.pic" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="swipe_box">
      <van-icon name="volume" size="16px" color="#ffffff" />
      <van-swipe :show-indicators="false" loop vertical :autoplay="4000" class="loopTips">
        <van-swipe-item
          class="swipe_text"
          v-for="(item, index) in lucyList"
          v-bind:key="index" v-html="$t('congratulations', [item.user, item.tjj_level_name,item.money])">
          
          </van-swipe-item>
      </van-swipe>
    </div>
    <ul  class="home-menu">
       <li> 
          <div @click="toWallet">
            <img  src="@/assets/images/qianbao.png">
          </div> 
          <p>{{$t('我的钱包')}}</p>
       </li>
       <li> 
          <div @click="toInvite">
            <img  src="@/assets/images/center.png">
          </div> 
          <p>{{$t('邀请好友')}}</p>
       </li>
        <li> 
          <div @click="toTeamReport">
            <img  src="@/assets/images/tb.png">
          </div> 
          <p>{{$t('团队报表')}}</p>
       </li>
       <li> 
          <div>
            <img  src="@/assets/images/customers.png" @click="toService">
          </div> 
          <p>{{$t('在线客服')}}</p>   
       </li>                    
    </ul>

    <img
      src="@/assets/images/promote-hf.png"
      width="100%"
      style="display: block;margin-bottom:8px"
      v-if="this.$lang == 'ja'"
      @click="toInvite"
    />     
    <img
      src="@/assets/images/promote-zh-CN.png"
      width="100%"
      style="display: block;margin-bottom:8px"
      v-if="this.$lang == 'zh'"
      @click="toInvite"
    />


    <van-cell-group>
      <van-cell :title="$t('任务大厅')" style="margin-bottom:5px" />
    </van-cell-group>
    <van-grid :border="false" :column-num="2" direction="horizontal" class="mygrid grid2">
      <van-grid-item v-for="(val, index) in typeList" :key="index" @click="onTask(index)">
        <span class="text">
          <h4>{{ val.name }}</h4>
        </span>
        <img :src="$imageUrl + val.logo" />
      </van-grid-item>
    </van-grid>
    
    <van-tabs
      type="card"
      color="#151d31"
      background="#0e1526"
      title-inactive-color="#ffffff"
      title-active-color="#ffffff"
    >
      <van-tab>
        <template #title>
          <div class="title_info">
          <img width="30" height="30" src="@/assets/images/tab1.png" />
          {{ $t('会员榜单') }}
          </div>
        </template>
        <div class="swipe_box_list">
          <vue-seamless-scroll
            :data="userShowList"
            class="loopTips"
            :class-option="optionSingleHeight"
          >
            <div class="swipe_text" v-for="(item, index) in userShowList" v-bind:key="index">
              <div class="box">
                <div class="header">
                  <img :src="require('@/assets/images/head_' + item.head_num + '.png')" />
                </div>
                <div class="text">
                  <p>{{ item.user }}</p>
                  <p>{{ item.info }}</p>
                </div>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </van-tab>

    </van-tabs>
    <!-- <van-popup
      v-model="showNotice"
      class="showNotice"
      :style="{ width: '80%', background: 'transparent', textAlign: 'center' }"
    >
      <dl class="NoticePopup">
        <dt v-text="$t('最新公告')"></dt>
        <dd>
          <p v-html="notice"></p>
        </dd>
      </dl>
      <a href="javascript:;" class="close" @click="showNotice = false">
        <van-icon name="clear" size="1rem" style="color: rgba(255, 255, 255, 0.8);" />
      </a>
    </van-popup> -->
  </div>
</template>

<script>
import { swipe } from "@/api/ad";
import { getNewNotice } from "@/api/notice";
import { getDownUrl } from "@/api/utils";
import {
  getLucyList,
  getTaskType,
  getUserShow
} from "@/api/utils";

export default {
  data() {
    return {
      adlist: [],
      list: [],
      lucyList: [],
      typeList: [],
      userShowList: [],
      notice: "",
      showNotice: false,
      app_download_url:""
    };
  },
  computed: {
    optionSingleHeight() {
      return {
        singleHeight: 72.5,
        waitTime: 2500
      };
    }
  },
  mounted() {
    this.GetDownUrl();
    this.getAdList();
    this.GetLucyList();
    this.GetTaskType();
    this.GetUserShow();
    //this.GetNewNotice();
  },
  methods: {
   GetDownUrl() {
      getDownUrl({})
        .then(response => {
          this.app_download_url = response.result;
        })
        .catch(error => {});
    },
    toService() {
      window.open(this.app_download_url, '_blank');
    },
    toTeamReport() {
      this.$router.push("/main/user/teamReport");
    },    
    toWallet() {
      this.$router.push("/main/user/wallet");
    },    
    
    toHelp() {
      this.$router.push("/main/user/help");
    },
    toVip() {
      this.$router.push("/main/vip");
    },
    toInvite() {
      this.$router.push("/main/user/invite");
    },
    onClickRight() {
      this.$router.push("/main/public/language");
    },
    GetNewNotice() {
      getNewNotice({ lang: this.$lang })
        .then(response => {
          this.notice = response.result;
          this.showNotice = true;
        })
        .catch(error => {});
    },

    //接任务
    onTask(index) {
     if(this.typeList[index].status==0){
         this.$toast({
            message: this.$t("敬请期待"),
            duration: 1500,
            forbidClick: true,
            position: "bottom"
          });
       return;
     }
      this.$router.push({
        path: "/main/tasklist",
        query: { index: index }
      });
    },
    //获取轮播图资源
    getAdList() {
      swipe({ as_id: 1, lang: this.$lang })
        .then(response => {
          this.adlist = response.result;
        })
        .catch(error => {});
    },
    //获取奖励用户
    GetLucyList() {
      getLucyList({})
        .then(response => {
          this.lucyList = response.result;
        })
        .catch(error => {});
    },
    GetUserShow() {
      getUserShow({})
        .then(response => {
          this.userShowList = response.result;
        })
        .catch(error => {});
    },
    //获取任务分类
    GetTaskType() {
      getTaskType({})
        .then(response => {
          this.typeList = response.result;
        })
        .catch(error => {});
    },
    linkUrl(url) {
      if (url != "#" && url != "") {
        this.$router.push(url);
      }
    }
  }
};
</script>

<style lang="stylus">
.homeView
  .home-menu
    display: flex;
    color: #fff;
    li 
      width: 25%;
      padding: 1em;
      text-align: center;
      p 
        font-size: 0.3rem;
      div
        display: flex;
        align-items: center;
        justify-content: center;
        width:1.3rem;
        height:1.3rem;
        margin: .2rem auto;
        border-radius: 50%;    
        img 
         width: 0.8rem;
         height: auto;
    li:first-child
      div
       background-color: #eda75e;
    li:nth-child(2)
      div
       background-color: #9758ff;
    li:nth-child(3)
      div
       background-color: #e57084;
    li:nth-child(4)
      div
       background-color: #818efa;              
  .van-ellipsis
    display: flex
    justify-content: center
    align-items: center
    max-width: 60%
    img
      height: 1rem
      vertical-align: top
      padding-top: 0.1rem
  .banner_auto
    position: relative
    .van-swipe
      img
        display: block
        box-sizing: border-box
        width: 100%
        background-color: #fff
        pointer-events: none
    .banner_bg
      position: absolute
      top: 0
      height: 3.33rem
      background: $mainColor
      width: 100%
  .swipe_box
    display: flex
    background-color: $cellBackColor
    align-items: center
    padding: 0 0.2rem
    .loopTips
      height: 0.9rem
      padding: 0.3rem 0
      color: #fff
      flex: 1
      .swipe_text
        line-height: 0.5rem
        margin-left: 0.2rem
        font-size: 0.34rem
  .mygrid
    padding-left: 8px
    .van-grid-item
      padding-right: 8px
      padding-bottom: 8px
    .van-grid-item__content
      flex-direction: initial
      background-color: #151d31 !important
      padding: 0.3rem
    h4
      margin: 0
      height: 0.6rem
      font-weight: 450
      font-size: 0.42rem
    img
      width: 0.6rem
    .text
      font-size: 0.36rem
      color: #fff
      flex: 1
  .grid1
    margin-top: 8px
  .grid2
    img
      width: 1.1rem
  .van-cell
    background-color: $cellBackColor !important
    color: #ffffff
  .van-tabs
    .van-tab__text
      img
        width: 0.4rem
        height: auto
        margin-right: 0.3rem
    .van-tabs__nav--card
      margin: 0
    .swipe_box_list
      background-color: #000000
      display: flex
      align-items: center
      .loopTips
        color: #fff
        flex: 1
        height: 7.6rem
        overflow: hidden
        .swipe_text
          font-size: 0.36rem
          background-color: $cellBackColor
          margin-top: 2px
          .box
            display: flex
            margin: 0 0.3rem
            padding: 0.25rem 0
            align-items: center
          .header
            height: 1.2rem
            width: 1.2rem
            background-color: #fff
            border-radius: 100%
            overflow: hidden
            padding: 0.05rem
            img
              width: 100%
              height: 100%
          .text
            margin-left: 0.2rem
            flex: 1
            p:last-child
              margin-top: 0.2rem
              color: #bbb
              font-size: 0.3rem
          .profit
            height: 0.5rem
            background-color: hsla(0, 0%, 100%, 0.5)
            border-radius: 0.4rem
            display: flex
            align-items: center
            padding: 0.1rem 0.2rem
            font-size: 0.36rem
            color: #0e1526
            img
              margin-right: 0.15rem
              height: 0.45rem
  .NoticePopup
    background: url('../../assets/images/bg_mine.png') no-repeat #052058
    background-size: contain
    height: 8.25rem
    padding: 0 0.7rem 0.7rem 0.7rem
    border-radius: 0.3rem
    position: relative
    text-align: center
    display: flex
    flex-direction: column
    dt
      font-size: 0.5rem
      font-weight: 600
      color: #fff
      line-height: 1.33rem
    dd
      background-color: rgba(0, 0, 0, 0.5)
      border-radius: 0.3rem
      overflow-y: auto
      overflow-x: hidden
      padding: 0.3rem
      color: #ccc
      flex: auto
      text-align: justify
      font-size: 0.36rem
      line-height: 0.4rem
  .NoticePopup~.close
    display: inline-block
    margin-top: 0.15rem
    font-size: 0
  .title_info
    display: flex;
    align-items: center;
    justify-content: center;  
</style>
